.KeyboardButton-0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-calculator-keyboard-primary-text) !important;
    background-color: var(--color-calculator-keyboard-primary);
    border-right: 2px solid var(--color-calculator-keyboard-button-border);
    border-top: 2px solid var(--color-calculator-keyboard-button-border);
    color: var(--color-calculator-keyboard-button);
    font-size: 24px;
    font-weight: lighter;
}

.KeyboardButton-0:active {
  background-color: var(--color-calculator-keyboard-primary-active);
}

.KeyboardButton-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-calculator-keyboard-secondary-text) !important;
  background-color: var(--color-calculator-keyboard-secondary);
  border-top: 2px solid var(--color-calculator-keyboard-button-border);
  border-right: 2px solid var(--color-calculator-keyboard-button-border);
}

.KeyboardButton-1-material{
  color:red;
}

.KeyboardButton-1:active {
background-color: var(--color-calculator-keyboard-secondary-active);
}

.KeyboardButton-1-active {
  background-color: var(--color-calculator-keyboard-secondary-open) !important
}

.KeyboardButton-outline-l{
  border-left: 2px solid var(--color-calculator-keyboard-button-border);
}

.KeyboardButton-outline-b{
  border-bottom: 2px solid var(--color-calculator-keyboard-button-border);
}

.KeyboardButton-inner{
  display: contents;
  vertical-align: middle;
  font-weight: 600;
}