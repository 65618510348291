.Keyboard {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    width: 100%;
    height: var(--calculator-keyboard-height);
    background-color: var(--color-calculator-keyboard-backgroud);
}

.Keyboard-settings-button{
  width: 100%;
  height: 100%;
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important;
  box-shadow: none !important;
}

.Keyboard-settings-popover{
  border-width: 2px;
}

.Keyboard-next-button-icon{
  font-size: 24px;
  font-weight: 600;
}

.popover-body{
  padding: 0px !important;
  width: 70vw;
  font-size: 17px;
  text-align: center;
}

@media (orientation:landscape){
    .Keyboard {
      height: var(--calculator-keyboard-height-landscape);
    }
    
    .popover-body{
      padding: 0px !important;
      width: 70vh;
      font-size: 17px;
    }
}