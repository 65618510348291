
.LandingPage-mobile{
    text-align: center;
    background-color: #f4fbff;
    padding: 20px;
    min-height: 100vh;
    position: relative;
}

.LandingPage-mobile-image{
    width: 40%;
    height: 40%;
}

.LandingPage-mobile-title{
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
}

.LandingPage-mobile-description{
    font-size: 14px;
    margin-bottom: 24px;
}

.LandingPage-desktop-description{
    font-size: 14px;
    margin-bottom: 24px;
    padding-left: 30vw;
    padding-right: 30vw;
    padding-top: 20px;
}

.LandingPage-mobile-body{
    position: relative;
}

.LandingPage-mobile-footer{
    width: 100%;
    bottom: 0;
    left: 0;
}

.LandingPage-mobile-footer-text{
    margin-bottom: 15px;
}

.MuiFormGroup-root-1{
    display: block !important;
}

.LandingPage-desktop{
    text-align: center;
    background-color: #f4fbff;
    min-height: 100vh;
    position: relative;
}

.LandingPage-desktop-image{
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.LandingPage-desktop-header{
    overflow: hidden;
    box-shadow: 0px 0 10px 0px #495260;
}

.LandingPage-desktop-footer{
    position: absolute;
    background-color: #f4fbff;
    width: 100%;
    left: 0;
    padding-top: 10px;
}

.LandingPage-desktop-title{
    display: inline-block;
}

.LandingPage-desktop-social-media-buttons{
    position: absolute !important;
    right: -80px !important;
    width: 240px;
}

.LandingPage-desktop-social-media-button{
    width: 41px;
    height: 41px;
    padding: 12px;
    background-color: #1e6498;
    border-radius: 50%;
    margin-left: 5px;
    opacity: .5;
    color: white;
    cursor: pointer;
}

.LandingPage-desktop-social-media-button:hover{
    opacity: .8;
    cursor: hand;
}

.LandingPage-desktop-body-shortcuts{
    font-family: 'Roboto', sans-serif;
    margin-top: 20px;
}

.LandingPage-desktop-body-shortcuts-title{
    font-size: 24px;
}

.LandingPage-calculator-mobile-footer{
    background-color: var(--color-calculator-keyboard-button-border);
    color: var(--color-calculator-keyboard-primary-text);
    height: var(--calculator-footer-height);
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 100vw;
    font-family: 'Roboto', sans-serif;
}

@media (orientation:landscape){
    .LandingPage-calculator-mobile-footer{
        display: none;
    }
}

.LandingPage-calculator-mobile-header{
    background-color: var(--color-calculator-keyboard-button-border);
    color: var(--color-calculator-keyboard-primary-text);
    height: var(--calculator-header-height-landscape);
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 100vw;
    font-family: 'Roboto', sans-serif;
}

@media (orientation:portrait){
    .LandingPage-calculator-mobile-header{
        display: none;
    }
}

.navbar{
    height: 100%;
}

.link-text{
    color: #003660;
}

.facebook-social-media-button{
    height: 16px;
    width: 0px;
}

.tooptip-top{
    top: -180px !important;
}

.tooptip-bottom{
    top: 180px !important;
}

.tooltip-inner{
    background-color:#e12f0c;
    cursor: pointer;
}

.tooltip-inner:hover{
    background-color: #952a16;
    background-image: url("../../close-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff57;
    cursor: pointer;
}

.tooltip .arrow::before{
    border-right-color:#e12f0c !important;
    border-left-color:#e12f0c !important;
}

.LandingPage-desktop-body-shortcuts{
    font-size: 24px;
    padding-bottom: 20px;
}

.LandingPage-desktop-body-shortcuts-numpad-title{
    padding-bottom: 10px;
}

.amzn-native-container {
    display: inline-block !important;
    min-width: 300px;
    max-width: 500px;
}
