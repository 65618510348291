.Calculator {
    text-align: center;
    display: var(--calculator-display);
    flex-direction: column;
    width: var(--calculator-width);
    height: var(--calculator-height);
  }
  
  .Calculator-display {
    width: 100%;
    height: var(--calculator-display-height);
    background-image: linear-gradient(var(--color-calculator-display-1), var(--color-calculator-display-2) , var(--color-calculator-display-3));
    overflow-y: auto;
  }
  
  .Calculator-display-table {
    display: table;
    height: 100%;
  }
  
  .Calculator-display-time{
    text-align: left;
    display: flex;
    color: var( --color-calculator-display-time-text)
  }
  
  .Calculator-display-time-hour{
    display: inline-block;
    color: var( --color-calculator-display-time-text);
    width: 40% !important;
    font-size: 20px !important;
    text-align: right;
  }
  
  .Calculator-display-time-hour:focus{
    background-color: var(--color-calculator-display-time-focus) !important;
  }
  
  .Calculator-display-time-minute{
    display: inline-block;
    color: var( --color-calculator-display-time-text);
    width: 40% !important;
    font-size: 20px !important;
  }
  
  .Calculator-display-time-minute:focus{
    background-color:  var(--color-calculator-display-time-focus) !important;
  }
  
  .Calculator-display-time-colon{
    display: inline-block;
    font-size: 24px;
    padding-left: 4px;
    padding-right: 4px;
  }
  
  .Calculator-display-form{
    display: table-cell;
    vertical-align: bottom;
  }
  
  .Calculator-display-result{
    display: table-cell;
    color: var( --color-calculator-display-result-text);
    vertical-align: bottom;
    font-size: 30px;
    padding-left: 12px;
    padding-right: 16px;
    white-space: nowrap;
    min-width: var(--calculator-display-result-width);
  }

  .Calculator-show-theme-modal{
    background-color: #ffffff00;
    box-shadow: none;
    display: inline-table !important;
  }

  .Calculator-show-theme-modal-apply-button{
    width: 60% !important;
    align-self: center;
  }

  .material-icons.md-dark{
    color: var(--color-calculator-keyboard-secondary-text) !important;
  }
  
  @media (orientation:landscape){
    .Calculator-display {
      height: var(--calculator-display-height-landscape);
    }
  }
  
  @keyframes Calculator-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @media (orientation:landscape){
    .Calculator{
      flex-direction: var(--calculator-flex-direction-landscape);
    }
  }
  
  @media (orientation:landscape){
    .Calculator-display-result{
      min-width: var(--calculator-display-result-width-landscape);
    }
  }